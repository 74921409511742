import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import styles from '@styles/components/Carousel.module.scss';
import { useRef } from 'react';

type Props = {
  RenderItem: Function,
  data: Array,
  slidesPerView?: number,
  slidesPerGroup?: number,
  spaceBetween?: number,
  showNavigation?: boolean,
  showPagination?: boolean,
  className?: string,
  prevButtonClass?: string,
  nextButtonClass?: string,
  iconArrow?: string,
};

export default function CarouselV2({
  data,
  RenderItem,
  slidesPerGroup = 4,
  slidesPerView = 4,
  spaceBetween = 15,
  showNavigation,
  showPagination,
  className,
  prevButtonClass,
  nextButtonClass,
  iconArrow = '/svg/arrow_nav.svg',
  ...rest
}: Props) {
  const swiperRef = useRef(null);

  const pagination = showPagination ? { pagination: { clickable: true } } : {};

  return (
    <div className="w-full relative">
      <Swiper
        {...rest}
        {...pagination}
        ref={swiperRef}
        modules={[Autoplay, Pagination]}
        className={`lg:p-3 lg:pb-8 pb-8 ${className}`}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        onSlideChange={() => {}}
        onSwiper={() => console.log()}>
        {data.map((item, index) => {
          return (
            <SwiperSlide key={index}>{RenderItem({ item, index })}</SwiperSlide>
          );
        })}
      </Swiper>
      {showNavigation && (
        <>
          <button
            aria-label="Prev Slide"
            onClick={() => swiperRef.current?.swiper.slidePrev()}
            className={`${styles.navigation} ${styles.prev} ${prevButtonClass}`}>
            <img src={iconArrow} alt="icon" className="w-full h-full" />
          </button>
          <button
            aria-label="Next Slide"
            onClick={() => swiperRef.current?.swiper.slideNext()}
            className={`${styles.navigation} ${styles.next} ${nextButtonClass}`}>
            <img
              src={iconArrow}
              alt="icon"
              className={`w-full h-full ${styles.iconNext}`}
            />
          </button>
        </>
      )}
    </div>
  );
}
