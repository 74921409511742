import CarouselV2 from '@components/CarouselV2';
import { usePromotionConfigs } from '@utils/configs/home/PromotionConfigs';
import styles from '@styles/components/Home/Home.module.scss';

type Props = {
  wrapperClass?: string,
};

export default function Promotion({ wrapperClass }: Props) {
  const promotionConfigs = usePromotionConfigs();
  return (
    <div className={`w-full bg-white pt-24px ${wrapperClass}`}>
      <CarouselV2
        autoplay={{
          delay: 9000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          375: {
            slidesPerView: 6,
            slidesPerGroup: 6,
            spaceBetween: 9,
          },
          1024: {
            slidesPerView: 12,
            slidesPerGroup: 12,
            spaceBetween: 12,
          },
        }}
        showPagination
        data={promotionConfigs}
        RenderItem={({ item, index }) => (
          <ItemPromotion {...item} onPress={item?.onPress} key={index} />
        )}
      />
    </div>
  );
}

type ItemPromotionProps = {
  Icon?: string,
  title?: string,
  onPress?: Function,
};

function ItemPromotion({ Icon, title, onPress }: ItemPromotionProps) {
  return (
    <div onClick={onPress} className={styles.itemPromotion}>
      <Icon className={styles.iconPromotion} />
      <div className={`${styles.titlePromotion} bodyText`}>{title}</div>
    </div>
  );
}
