import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import MyToast from '@components/MyToast';
import { defaultToastOption } from '@utils/configs/Toast';
import React from 'react';
import { useChatContext } from '@utils/context/chatContext';
import { useIsLogin } from '@hooks/auth/login/useIsLogin';
import { isMobile } from '@utils/helper';
const Bank = dynamic(() => import('@public/svg/bank.svg'));
const Balance = dynamic(() => import('@public/svg/balance.svg'));
const Map = dynamic(() => import('@public/svg/map.svg'));
const ChatService = dynamic(() => import('@public/svg/chatService.svg'));
const Insurance = dynamic(() => import('@public/svg/insurance.svg'));
const Shopping = dynamic(() => import('@public/svg/shopping.svg'));
const CustomerCare = dynamic(() => import('@public/svg/customerCare.svg'));
const Valuation = dynamic(() => import('@public/svg/valuation.svg'));
const Environment = dynamic(() => import('@public/svg/environment.svg'));
const ConstructionCompany = dynamic(() =>
  import('@public/svg/constructionCompany.svg'),
);
const IndustrialHygiene = dynamic(() =>
  import('@public/svg/industrialHygiene.svg'),
);
const HomeData = dynamic(() => import('@public/svg/homeData.svg'));
const SmartCity = dynamic(() => import('@public/svg/smartCity.svg'));
const SmartHome = dynamic(() => import('@public/svg/smartHome.svg'));
const Material = dynamic(() => import('@public/svg/material.svg'));
const ManageRealEstate = dynamic(() =>
  import('@public/svg/manageRealEstate.svg'),
);
const PlaneTicket = dynamic(() => import('@public/svg/planeTicket.svg'));
const Course = dynamic(() => import('@public/svg/course.svg'));
const LiveStream = dynamic(() => import('@public/svg/liveStream.svg'));
const VirtualReality = dynamic(() => import('@public/svg/virtualReality.svg'));
const Technology = dynamic(() => import('@public/svg/technology.svg'));
const Finance = dynamic(() => import('@public/svg/finance.svg'));
const DataService = dynamic(() => import('@public/svg/dataService.svg'));
const PosMachine = dynamic(() => import('@public/svg/posMachine.svg'));
const BuildingManager = dynamic(() =>
  import('@public/svg/buildingManager.svg'),
);
const Travel = dynamic(() => import('@public/svg/travel.svg'));
const MergerAndAcquisition = dynamic(() =>
  import('@public/svg/mergerAndAcquisition.svg'),
);
const Internal = dynamic(() => import('@public/svg/internal.svg'));
import { useGetUserInfo } from '@hooks/publishRealEstate/useGetUserInfo';

export const usePromotionConfigs = () => {
  const router = useRouter();
  const { setShowChat, setShowChatMobile } = useChatContext();
  const isLogin = useIsLogin();
  const { userInfo } = useGetUserInfo();
  const redirectToInternal = () => {
    const accessToken = window.localStorage.getItem('silverBullet');
    window.flutter_inappwebview.callHandler('mobileNavigateTo', {
      ...userInfo?.user,
      accessToken,
    });
  };
  return [
    {
      Icon: Internal,
      title: 'Quản lý nội bộ',
      onPress: () => {
        redirectToInternal();
      },
    },
    {
      Icon: Bank,
      title: 'Ngân hàng & vay',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: Balance,
      title: 'Luật & công chứng',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: Map,
      title: 'Bản đồ quy hoạch',
      onPress: async () => {
        if (!isLogin) {
          toast(
            <MyToast
              type="warning"
              message="Bạn cần đăng nhập để sử dụng chức năng này."
            />,
            defaultToastOption,
          );
        } else {
          await router.push('/ban-do?mapType=internal');
        }
      },
    },
    {
      Icon: ChatService,
      title: 'Chat/ Video call',
      onPress: () => {
        if (!isLogin) {
          toast(
            <MyToast
              type="warning"
              message="Bạn cần đăng nhập để sử dụng chức năng này."
            />,
            defaultToastOption,
          );
        } else {
          if (isMobile) {
            setShowChatMobile(true);
          } else {
            setShowChat(true);
          }
        }
      },
    },
    {
      Icon: Insurance,
      title: 'Bảo hiểm',
      onPress: () => {
        window.open(
          userInfo?.user?.memberId
            ? `https://realtech247.vifo.vn/?refer_id=${userInfo?.user?.id}`
            : `https://realtech247.vifo.vn/`,
        );
      },
    },
    {
      Icon: Shopping,
      title: 'Mua sắm',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: CustomerCare,
      title: 'Chăm sóc khách hàng',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: Valuation,
      title: 'Định giá',
      onPress: async () => {
        if (!isLogin) {
          toast(
            <MyToast
              type="warning"
              message="Bạn cần đăng nhập để sử dụng chức năng này."
            />,
            defaultToastOption,
          );
        } else {
          await router.push('/dinh-gia');
        }
      },
    },
    {
      Icon: Environment,
      title: 'Môi trường',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: ConstructionCompany,
      title: 'Công ty xây dựng',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: IndustrialHygiene,
      title: 'Vệ sinh công nghiệp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: HomeData,
      title: 'Data nhà',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: SmartCity,
      title: 'Smart city',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: SmartHome,
      title: 'Smart home',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: Material,
      title: 'Vật tư',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: ManageRealEstate,
      title: 'Quản lí BĐS',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: PlaneTicket,
      title: 'Vé máy bay',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: Course,
      title: 'Khóa học',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: LiveStream,
      title: 'Livestream',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: VirtualReality,
      title: 'Thực tế ảo',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: Technology,
      title: 'Công nghệ',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: Finance,
      title: 'Tài chính',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: DataService,
      title: 'Data Service',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: PosMachine,
      title: 'Máy POS',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: BuildingManager,
      title: 'Quản lí tòa nhà',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: Travel,
      title: 'Du lịch',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      Icon: MergerAndAcquisition,
      title: 'Mua bán & sáp nhập',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
  ];
};
